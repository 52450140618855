import React, { Component } from 'react';
import "./ReferralManagemet.css";
// import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import moment from "moment";
import Drawer from '@mui/material/Drawer';
import CloseRounded from '@mui/icons-material/CloseRounded';
import DataTable from 'react-data-table-component';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Android } from '@mui/icons-material';
import androidIcon from "../../assets/image/android.svg";
import iosIcon from "../../assets/image/ios.svg";

class Subscription extends Component {
    state = { 
        // columns : [
        //     {
        //         name: "User",
        //         selector: row => row.username,
        //         sortable: true
        //     },
        //     {
        //         name: "Status",
        //         selector: row => row.id,
        //         sortable: true
        //     },
        //     {
        //         name: "Metode Pembayaran",
        //         selector: row => row.metode,
        //         sortable: true
        //     },
        //     {
        //         name: "Voucher",
        //         selector: row => row.foto !== null && row.foto !== undefined && row.foto.length > 0 ? <span>Ada</span> : <span>Belum ada</span>,
        //         sortable: true
        //     },
            
        //     {
        //         name: "Device",
        //         selector: row => this.badgeStatus(row.status),
        //         sortable: true
        //     },
        //     {
        //         name: "Jenis Paket",
        //         selector: row => this.badgeStatus(row.status),
        //         sortable: true
        //     },
        //     {
        //         name: "Nominal",
        //         selector: row => this.badgeStatus(row.status),
        //         sortable: true
        //     },
        //     {
        //         name: "Action",
        //         // selector: row => row.photo_url !== undefined && row.photo_url !== null && row.photo_url.length > 0 ? row.action : <></>
        //         selector: row => row.action
        //     }
        // ],
        columns : [
            {
                name: "ID Transaksi",
                selector: row => row.id,
                sortable: true
            },
            {
                name: "Nama User",
                selector: row => row.username,
                sortable: true
            },
            {
                name: "Tanggal Transaksi",
                selector: row => row.created_at_formatted,
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = moment(rowA.created_at).toDate();
                    const b = moment(rowB.created_at).toDate();

                    return a > b ? 1 : -1

                }
            },
            {
                name: "Metode",
                selector: row => row.metode,
                sortable: true
            },
            {
                name: "Bukti Bayar",
                selector: row => row.foto !== null && row.foto !== undefined && row.foto.length > 0 ? <span>Ada</span> : <span>Belum ada</span>,
                sortable: true
            },
            
            {
                name: "Status",
                selector: row => this.badgeStatus(row.status),
                sortable: true
            },
            {
                name: "Action",
                // selector: row => row.photo_url !== undefined && row.photo_url !== null && row.photo_url.length > 0 ? row.action : <></>
                selector: row => row.action
            }
        ],
        data : [], 
        top: false,
        left: false,
        bottom: false,
        right: false,
        selectedUser : {
            nama: "",
            no_ktp: "",
            tanggal_lahir: "",
            tempat_lahir: "",
            referal_code: ""
        },
        //all, owner store, cashier
        status: "all",
        isLoading: false,
        keyword: "",
        showFormRejection: false,
        errorMessage: ""
    }

    defaultSelectedUser = () => {
        return {
            nama: "",
            no_ktp: "",
            tanggal_lahir: "",
            tempat_lahir: "",
            referal_code: "",
            downline: []
        };
    }

    options = {
        filterType: 'checkbox',
    };

    STATUS_WAITING_VERIFICATION = "waiting verification";
    STATUS_ACTIVE = "active";
    STATUS_VERIFIED = "verified";
    STATUS_REJECT = "rejected";

    badgeStatus = (status) => {
        if(status === this.STATUS_VERIFIED){
            return (<div className="badge badge-success">Verified</div>);
        }
        if(status === this.STATUS_WAITING_VERIFICATION){
            return (<div className="badge badge-success">Waiting</div>);
        }
        if(status === this.STATUS_REJECT){
            return (<div className="badge badge-success">Rejected</div>);
        }
    }

    loadUserMarketing = (statusSubscription) => {

        let params = {
            // filter:{
                // photo_url : {$eq: "", $exists:true}
                // status: {$eq: "waiting verification"}
            // },
            // filter: {},
            sort  : {created_at: -1},
            // sort  : {foto: -1, created_at: -1},
            limit : 100
        };

        if(statusSubscription !== "all"){
            if(statusSubscription === this.STATUS_WAITING_VERIFICATION){
                params.filter = {
                    status: statusSubscription,
                    // foto: {$ne: "", $exists:true}
                }
            }else{
                params.filter = {
                    status: statusSubscription
                }
            }
        }
        else{
            params.filter = {}
        }

        if(this.state.keyword.length > 0){
            params.filter.username = {
                $regex: this.state.keyword,
                $options: "i"
            }
        }
    
        console.log("PARAMS USERS", JSON.stringify(params), "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        this.setState({isLoading: true}, () => {
            axios
          .post(
            Api.listBuktiTransferSubscription(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("USERSRESPONSE", response.data);
            const responseData = response.data;
            const userDatas = [];
            
            responseData.data.forEach(element => {
                
                element.created_at_formatted = moment(element.created_at).format("DD-MM-YYYY HH:mm");
                element.action = this.action(element);
                
                userDatas.push(element);
            });

            this.setState({data: userDatas, isLoading: false});
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
    };

    submitVerify = (idSubscription) => {
        axios.post(
            Api.verifySubscription(idSubscription),
            {},
            Api.getRequestHeader()
          ).then((response) => {
            console.log("VERIFY", response.data);
            
            this.setState({isLoading: false, right: false, selectedUser: this.defaultSelectedUser}, () => {
                this.loadUserMarketing()
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
        });
    }

    submitRejection = (idSubscription, rejectReason) => {

        if(rejectReason === undefined || rejectReason.length <= 0){
            this.setState({errorMessage: "Reject reason should not empty"});
            return;
        }

        axios.post(
            Api.rejectSubscription(idSubscription),
            {
                reject_reason: rejectReason
            },
            Api.getRequestHeader()
          ).then((response) => {
            console.log("VERIFY", response.data);
            
            this.setState({isLoading: false, right: false, showFormRejection: false, selectedUser: this.defaultSelectedUser}, () => {
                this.loadUserMarketing()
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
        });
    }

    componentDidMount() {
        // this.setState({status: this.STATUS_WAITING_VERIFICATION}, () => {
        this.setState({status: "all"}, () => {
            // this.loadUserMarketing(this.STATUS_WAITING_VERIFICATION);
            this.loadUserMarketing("all");
        });
    }

    toggleDrawer = (open, userInfo) => (event) => {
        console.log(userInfo);
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        
        let defaultInfo = this.defaultSelectedUser();

        this.setState({right: open, showFormRejection: false, selectedUser: !open ? defaultInfo : userInfo });
    };

    action = (subscriptionInfo) => {      
        return (
            <div>
                <span className="btn btn-primary" onClick={this.toggleDrawer(true, subscriptionInfo)}>Detail</span>&nbsp;
                {/* <span className="btn btn-primary" onClick={this.toggleDrawer(true, userInfo)}>Gen Code</span> */}
            </div>
        );
    }

    handleKeywordChange = (event) => {
        this.setState({keyword: event.target.value}, ()=>{
            this.loadUserMarketing(this.state.status);
        });
    }

    handleRejectReasonChange = (event) => {
        this.setState({rejectReason: event.target.value});
    }

    handleShowFormRejection = () => {
        this.setState({showFormRejection: true});
    }

    handleHideFormRejection = () => {
        this.setState({showFormRejection: false});
    }

    list(anchor,){
        return (
        <div className="info" role="presentation"
        //   onClick={this.toggleDrawer(anchor, false)}
        //   onKeyDown={this.toggleDrawer(anchor, false)}
        >
          <div className="container">
              <div className="row">
                    <div className="col-11">
                        <h1>Subscription Info</h1>
                    </div>
                   
                  <div className="col-1">
                      <CloseRounded onClick={() => {this.toggleDrawer(false, {})}} />
                  </div>
              </div>
              <br />
                <div className="row">
                    <div className="col-5">
                        ID Transaksi
                    </div>
                    <div className="col-1"> : </div>
                    <div className="col-6">
                       {this.state.selectedUser.id}
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-5">
                        Nama
                    </div>
                    <div className="col-1"> : </div>
                    <div className="col-6">
                       {this.state.selectedUser.username}
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-5">
                        Tanggal
                    </div>
                    <div className="col-1"> : </div>
                    <div className="col-6">
                       {this.state.selectedUser.created_at_formatted}
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-5">
                        Metode
                    </div>
                    <div className="col-1"> : </div>
                    <div className="col-6">
                       {this.state.selectedUser.metode}
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-5">
                        Bukti Bayar
                    </div>
                    <div className="col-1"> : </div>
                    <div className="col-6">
                       
                    </div>
                </div>
                <img src={this.state.selectedUser.foto} />
                <hr />
                
                {
                    this.state.selectedUser.status === this.STATUS_REJECT ? 
                    (<div>
                        <div className="row">
                            <div className="col-5">
                                Reject Reason
                            </div>
                            <div className="col-1"> : </div>
                            <div className="col-6">
                                {this.state.selectedUser.reject_reason}
                            </div>
                        </div>
                        <hr />
                    </div>) : 
                    (<></>)
                }

                {
                    this.state.showFormRejection ? 
                    (<div className='container border' style={{paddingTop: 10, paddingBottom: 10}}>
                        <h4>Reject Subscription</h4>
                        <div className="text-danger text-italic text-small">{this.state.errorMessage}</div>
                        <TextField 
                            onChange={this.handleRejectReasonChange}
                            id="outlined-basic" 
                            value={this.state.rejectReason} 
                            fullWidth 
                            label="Reason" variant="outlined" 
                        />
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-6">
                                <div className="btn btn-danger btn-block" onClick={() => this.submitRejection(this.state.selectedUser._id, this.state.rejectReason)}>
                                    Reject
                                </div>
                            </div>
                            <div className="col-6" onClick={() => this.handleHideFormRejection()}>
                                <div className="btn btn-warning btn-block">
                                    Cancel
                                </div>
                            </div>
                        </div>
                    </div>) : 
                    (
                        <div className="text-center">
                            {
                                this.state.selectedUser.status !== this.STATUS_VERIFIED ? 
                                    (
                                        <span className="btn btn-primary btn-block" onClick={() => this.submitVerify(this.state.selectedUser._id)}>
                                            Verify
                                        </span>
                                    ) :
                                    (<></>)
                            }

                            {
                                this.state.selectedUser.status !== this.STATUS_VERIFIED && this.state.selectedUser.status !== this.STATUS_REJECT ? 
                                    (
                                        <span className="btn btn-danger btn-block" onClick={() => this.handleShowFormRejection()}>
                                            Reject
                                        </span>
                                    ) :
                                    (<></>)
                            }
                        </div>
                    )
                }

                
          </div>
          
        </div>
      );
    }

    getFilterClasses = (role) => {
        let classes = "col-3";
        if(role === this.state.status){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }

    handleChangeFilter = (status) => {
        console.log("STATUS : ", status);

        if(status === this.STATUS_REJECT){
            // const columns = [...this.state.columns];
            // columns.
        }

        this.setState({status: status});
        this.loadUserMarketing(status);
    }

    render() { 
        return ( 
            <div>
                {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                  <Drawer anchor={anchor} open={this.state[anchor]} 
                    onClose={this.toggleDrawer(false,{})}
                    >
                    {this.list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}

                <div className="containera-fluid no-padding">
                    {/* <Paper elevation={0} sx={{padding: "10px", border: "solid 1px #dedede"}}>
                        <Box>Filter</Box>
                        <Box>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Box sx={{marginTop: "4px"}}>
                                    <MyDateRangePicker style={{marginTop: "10px"}} startDate={this.state.startDate} endDate={this.state.endDate} onDateChanged={this.handleDateChanged}/>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="cb-lokasi">Device</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={"Semua"}
                                            label="Device"
                                            onChange={(event) => {}}
                                        >
                                        <MenuItem value={"Semua"}>Semua</MenuItem>
                                        <MenuItem value={"ANDROID"}>Android</MenuItem>
                                        <MenuItem value={"IOS"}>iOS</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="cb-lokasi">jenis Paket</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={"Semua"}
                                        label="Jenis Paket"
                                        onChange={(event) => {}}
                                        >
                                        <MenuItem value={"Semua"}>Semua</MenuItem>
                                        <MenuItem value={20}>?</MenuItem>
                                        <MenuItem value={30}>??</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="cb-lokasi">Durasi</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={"Semua"}
                                        label="Durasi"
                                        onChange={(event) => {}}
                                        >
                                        <MenuItem value={"Semua"}>Semua</MenuItem>
                                        <MenuItem value={20}>?</MenuItem>
                                        <MenuItem value={30}>??</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="cb-lokasi">Role Pengguna</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={"Semua"}
                                        label="Role Pengguna"
                                        onChange={(event) => {}}
                                        >
                                        <MenuItem value={"Semua"}>Semua</MenuItem>
                                        <MenuItem value={20}>?</MenuItem>
                                        <MenuItem value={30}>??</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="cb-lokasi">Jenis Pengguna</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={"Semua"}
                                        label="jenis Pengguna"
                                        onChange={(event) => {}}
                                        >
                                        <MenuItem value={"Semua"}>Semua</MenuItem>
                                        <MenuItem value={20}>?</MenuItem>
                                        <MenuItem value={30}>??</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} textAlign={"right"}>
                                    <Button variant='contained' color='primary' sx={{borderRadius: "100px"}}>Tampilkan</Button>
                                    <Button variant='text' color='primary'>Reset</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    <Box marginTop={"10px"} marginBottom={"10px"}>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Paper elevation={3} sx={{padding: "5px", textAlign: "center"}}>
                                    <strong>
                                        <Box sx={{color: "blue", fontSize: "27px"}}>Rp. 50.000.000</Box>
                                    </strong>
                                    <small>Total Pendapatan</small>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper elevation={3} sx={{padding: "5px"}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4} sx={{textAlign: "center"}}>
                                            <strong>
                                                <Box sx={{color: "black", fontSize: "27px"}}>4000</Box>
                                            </strong>
                                            <Box sx={{color: "blue"}}>
                                                <small>Total Langganan</small>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box marginY={"4px"} marginLeft={"-3px"}>
                                                <img src={androidIcon} alt=''/> 2.000
                                            </Box>
                                            <Box marginY={"4px"}>
                                                <img src={iosIcon} alt=''/> 2.000
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={0.1} sx={{marginTop: "5px"}}>
                                                <Grid item xs={4} sx={{textAlign: "center"}}>
                                                    <Box sx={{backgroundColor: "#dededd", marginBottom: "5px"}}>Trial</Box>
                                                    <strong>20</strong>
                                                </Grid>
                                                <Grid item xs={4} sx={{textAlign: "center"}}>
                                                    <Box sx={{backgroundColor: "#dededd", marginBottom: "5px"}}>Basic</Box>
                                                    <strong>300</strong>
                                                </Grid>
                                                <Grid item xs={4} sx={{textAlign: "center"}}>
                                                    <Box sx={{backgroundColor: "#dededd", marginBottom: "5px"}}>Trial</Box>
                                                    <strong>30</strong>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={3}>
                                <Paper elevation={3} sx={{padding: "5px"}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6} sx={{textAlign: "center"}}>
                                            <strong>
                                                <Box sx={{color: "black", fontSize: "27px"}}>159</Box>
                                            </strong>
                                            <Box sx={{color: "blue"}}>
                                                <small>user Baru</small>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sx={{textAlign: "center"}}>
                                            <strong>
                                                <Box sx={{color: "black", fontSize: "27px"}}>159</Box>
                                            </strong>
                                            <Box sx={{color: "blue"}}>
                                                <small>User Lama</small>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box> */}
                    <div className="container-fluid header-page">
                        {/* <h2 className="mt-0">Subscription Monitoring </h2> */}
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <TextField 
                                    onChange={this.handleKeywordChange}
                                    id="subscription-search" 
                                    value={this.state.keyword} 
                                    fullWidth 
                                    label="Pencarian" variant="outlined" 
                                    size='small'
                                />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 text-right">
                                {/* <ExportTransaksi data={this.state.data} />&nbsp;
                                <span className="btn btn-primary" onClick={this.toggleDrawer(true, {})}>Tambah</span> */}
                                <div className="row filter-container">
                                    <div onClick={() => this.handleChangeFilter("all")} className={this.getFilterClasses("all")}>
                                        All
                                    </div>
                                    <div onClick={() => this.handleChangeFilter(this.STATUS_WAITING_VERIFICATION)} className={this.getFilterClasses(this.STATUS_WAITING_VERIFICATION)}>
                                        Waiting
                                    </div>
                                    <div onClick={() => this.handleChangeFilter(this.STATUS_VERIFIED)} className={this.getFilterClasses(this.STATUS_VERIFIED)}>
                                        Verified
                                    </div>
                                    <div onClick={() => this.handleChangeFilter(this.STATUS_REJECT)} className={this.getFilterClasses(this.STATUS_REJECT)}>
                                        Rejected
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid dashboard-content">
                        {/* <div className="row">
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                                <small>Tampilkan hasil untuk :</small> 
                                <br />
                                {this.state.role === "all" ? "Semua" : this.state.role === "owner store" ? "Pemilik" : "Kasir"}
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-8 col-xs-12">
                                <div className="row filter-container">
                                    <div onClick={() => this.handleChangeFilter("all")} className={this.getFilterClasses("all")}>
                                        Semua
                                    </div>
                                    <div onClick={() => this.handleChangeFilter("owner store")} className={this.getFilterClasses("owner store")}>
                                        Pemilik
                                    </div>
                                    <div onClick={() => this.handleChangeFilter("cashier")} className={this.getFilterClasses("cashier")}>
                                        Kasir
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <br /> */}
                        <DataTable 
                            title="" 
                            data={this.state.data} 
                            columns={this.state.columns} 
                            options={this.options} 
                            // selectableRows
                            pagination
                            progressPending={this.state.isLoading}
                            // progressComponent={<div>Loading</div>}
                        />

                    </div>
                </div>
            </div>
        );
    }
}
 
export default Subscription;

function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState(
      props.startDate && props.endDate ? [props.startDate.toDate(), props.endDate.toDate()] :
      [moment(new Date()).subtract(1, "Y").toDate(), Date.now()]
    );
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
  
    return (
      <>
        <ReactDatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              // if(diffs > 35){
              //   setDateRange([null, null]);
              //   setMessage("Rentang tanggal max 35 hari");
              //   return
              // }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }
  
          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
          style={{borderColor: "#FFFFFF"}} 
          wrapperClassName="my-datepicker"
          className='border rounded rounded-lg border-secondary p-1 text-center'
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }